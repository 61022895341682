<script setup lang="ts">
import Loader from '~/components/shared/loader.vue'

import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { getTrackDistantApiData } from '~/helpers/distantApi/getTrackDistantApi'

type Props = {
  trackUrl: string
}

const props = defineProps<Props>()

const { getMediaUrl } = useGetMediaUrl()

const thumbnailCache: Record<string, string | undefined> = {}

const imageSrc = ref<string | null>(null)
const loaded = ref(false)

async function fetchImageSrc() {
  if (thumbnailCache[props.trackUrl] !== undefined) {
    imageSrc.value = thumbnailCache[props.trackUrl] ?? null
    loaded.value = true
  } else {
    const resp = await getTrackDistantApiData(props.trackUrl)

    if (!resp.err) {
      thumbnailCache[props.trackUrl] = resp.thumbnail ?? undefined
      imageSrc.value = resp.thumbnail || null
    } else {
      imageSrc.value = null
    }

    loaded.value = true
  }
}

onMounted(fetchImageSrc)

watch(
  () => props.trackUrl,
  () => {
    imageSrc.value = ''
    fetchImageSrc()
  },
)
</script>

<template>
  <div class="tw-relative tw-block tw-h-full tw-w-full tw-rounded-xs">
    <transition mode="out-in" name="fade">
      <Loader v-if="!imageSrc && !loaded" key="loader" class="tw-bg-white" />
      <img
        v-else-if="imageSrc"
        key="img"
        loading="lazy"
        class="picture"
        :src="imageSrc"
        alt=""
      />
      <img
        v-else
        key="placeholder"
        loading="lazy"
        class="picture fallback tw-p-2 tw-contrast-50 tw-filter"
        :src="getMediaUrl('Logo.svg')"
        alt=""
      />
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.picture {
  @apply tw-h-full tw-w-full tw-overflow-hidden tw-rounded-xs tw-object-cover tw-object-center;
}
</style>
